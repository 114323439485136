.pagination {
  /* margin: 15px auto; */
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.pagination>.active>a {
  /* background: linear-gradient(to right, #F15B5B, #FFBE00); */
  background-color: #ea504f;
  color: #fff;
  border-radius: 8px;
  border: none;
}

.pagination>li>a {
  /* border: 1px solid #47ccde ; */
  padding: 8px 13px;
  outline: none;
  cursor: pointer;
  border: none;
  font-size: 14px;
  padding-left: 18px;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-right: 18px;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  /* background: linear-gradient(to right, #F15B5B, #FFBE00); */
  background-color: #ea504f;
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: #3d204a;
  margin-left: 8px;
  border: 1px solid #72467a;
  border-radius: 10px;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: 10px
}



.paginationNextBtn>a {
  /* background: linear-gradient(to right, #F15B5B, #FFBE00); */
  background-color: #fff;
  color: #ada4af !important;
  border: 1px solid #ada4af !important;
  border-radius: 10px;
}

.paginationPrevBtn>a {
  background-color: #fff;
  color: #ada4af !important;
  border: 1px solid #ada4af !important;
  border-radius: 10px;
}

.activePaginationNextBtn>a {
  background-color: #ea504f;
  color: #fff !important;
  border: none !important;
  outline: none;

}

.activePaginationPrevBtn>a {
  background-color: #ea504f;
  color: #fff !important;
  border: none !important; 
  outline: none;

}

.MuiPaper-elevation1::-webkit-scrollbar {
  width: 8px;
}

.MuiPaper-elevation1::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

.MuiPaper-elevation1::-webkit-scrollbar-thumb {
  background: #b2b2b2;
  border-radius: 25px;
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline{
  border-color: transparent !important;
}

@media print {
  .tableHeight2{
   width: 100%;
   height: 100% !important;
  }
.visible-print{
  display: block;
  width: auto;
  height: auto;
  overflow: visible;  
 }
 html, body {
  border: 1px solid white;
  height: 99%;
  page-break-after: avoid;
  page-break-before: avoid;
}
}